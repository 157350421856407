<template>
  <div class="auth-wrapper auth-v2 login-bg">
    <b-row class="auth-inner m-0 d-lg-flex align-items-center p-5">
      <div class="login-center">
        <div class="login-inner">
          <!-- Left Text-->
          <b-col
            lg="6"
            class="d-none d-lg-flex align-items-center p-1"
          >
            <div class="w-100 d-lg-flex align-items-center justify-content-center">
              <b-img
                fluid
                :src="imgUrl"
                alt="Login V2"
              />
            </div>
          </b-col>
          <!-- /Left Text-->

          <!-- Login-->
          <b-col
            lg="6"
            class="d-flex align-items-center auth-bg p-lg-2"
          >
            <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto"
            >
              <b-card-title
                title-tag="h2"
                class="font-weight-bold mb-1"
              >
                Welcome to Nudg Dashboard! 👋
              </b-card-title>
              <b-card-text class="mb-2">
                Please sign-in to your account and start the adventure
              </b-card-text>

              <!-- form -->
              <!-- <validation-observer ref="loginValidation"> -->
              <validation-observer
                ref="loginForm"
                #default="{invalid}"
              >
                <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent="login"
                >
                  <!-- email -->
                  <b-form-group
                    label="Email"
                    label-for="login-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false:null"
                        name="login-email"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- forgot password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">Password</label>
                      <!-- <b-link :to="{name:'auth-forgot-password-v2'}">
                        <small>Forgot Password?</small>
                      </b-link> -->
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- checkbox -->
                  <b-form-group>
                    <b-form-checkbox
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
                    >
                      Remember Me
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- submit buttons -->
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                  >
                    Sign in
                  </b-button>
                </b-form>
              </validation-observer>

            </b-col>
          </b-col>
          <!-- /Login-->
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    // BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      // sideImg: require('@/assets/images/pages/login-v2.svg'),
      sideImg: require('@/assets/images/pages/login-screen.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  // methods: {
  //   validationForm() {
  //     this.$refs.loginValidation.validate().then(success => {
  //       if (success) {
  //         this.$toast({
  //           component: ToastificationContent,
  //           props: {
  //             title: 'Form Submitted',
  //             icon: 'EditIcon',
  //             variant: 'success',
  //           },
  //         })
  //       }
  //     })
  //   },
  // },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const { userData } = response.data
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged in. Now you can start to explore!',
                      // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  })
                })
            })
            .catch(error => {
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
